import React from 'react'
import { Link } from 'gatsby'
import logo from "../../assets/images/BNGTransparent.png"
import footerMap from "../../assets/images/footer-map.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
       
        <footer className="footer-area bg-color">
            
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img src={logo} alt="logo" />
                            </a>
                            {/*<p>Bumi Nusantara Group, a leading holding company that invests in and manages a diverse portfolio of companies across various industries. Our mission is to create long-term value for our partners and stakeholders by identifying and acquiring businesses with strong growth potential and supporting their development.</p>*/}

                            <ul className="social-link">
                                <li>
                                    <Link to="https://www.facebook.com/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-facebook'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://twitter.com/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-twitter'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/buminusantarajaya?igsh=d21pOTRzOGx5MnZh" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-instagram'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/company/bnj/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-linkedin'></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget pl-5">
                        


                        </div>
                    </div>


                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Menu</h3>

                            <ul className="footer-contact-info">
                                <li style={{ width: '250px', color: '#0F477C' }}>
                                    {/*<i className='bx bx-map'></i>*/}
                                    <div>
                                        <Link to='/'>Home</Link>
                                    </div>
                                    <div>
                                        <Link to='/about-us'>About Us</Link>
                                    </div>
                                    <div>
                                        <Link to='#'>Our Business</Link>
                                    </div>
                                    <div>
                                        <Link to='#'>Media Center</Link>
                                    </div>
                                    <div>
                                        <Link to='#'>Career</Link>
                                    </div>
                                    <div>
                                        <Link to='/contact'>Contact</Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    {/*<div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Our Business</h3>
                            <ul className='footer-contact-info'>
                                <li style={{color: '#0F477C'}}>
                                <div>
                                    <Link to='/trading'>Trading</Link>
                                </div>
                                <div>
                                    <Link to='/trading'>Fisheries</Link>
                                </div>
                                
                                <div>
                                    <Link to='/trading'>FMCG</Link>
                                </div>
                                <div>
                                    <Link to='/trading'>Renewable Energy</Link>
                                </div>
                                <div>
                                    <Link to='/trading'>Fleet</Link>
                                </div>
                                </li>
                            </ul>

                        </div>
                    </div>*/}

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget pl-5">
                        <h3>Address</h3>
                        <ul className='footer-contact-info'>
                                <li style={{color: '#0F477C'}}>
                                <div>
                                    <p>Prosperity Tower, Level 50 F District 8,</p>
                                </div>
                                <div>
                                    <p>SCBD Lot 28 Jl. Jend Sudirman Kavling</p>
                                </div>
                                <div>
                                    <p>52-53 Jakarta 12190, Indonesia</p>
                                </div>
                                </li>
                            </ul>


                        </div>
                    </div>
                </div>

                <div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div>
                <div className="footer-bottom-area">
                    <div className="d-flex justify-content-center">
                        <p style={{ color: 'white' }}> Copyright @{currentYear} <strong>Bumi Nusantara Group</strong> All rights reserved <a target="_blank" href="https://buminusa.co.id" rel="noreferrer">BNG</a></p>
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
                        </div>
            
        </footer>
      
    );
}

export default Footer;